import { css } from "styled-components";

const breakpoints = {
  mobile: 768,
  largeMobile: 832,
  tablet: 1024,
  desktop: 1250,
  large: 1920,
};

// iterate through the sizes and create a media template
export const media: any = Object.keys(breakpoints).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize: number = breakpoints[label] / 16;
  accumulator[label] = (arg, ...args: any[]) => css `
    @media (min-width: ${emSize}em) {
      ${css(arg, ...args)}
    }
  `;
  return accumulator;
}, {});

export default {
  fontFamily: {
    base: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    PTSans: 'PT Sans, sans-serif, sans-serif',
    Raleway: 'Raleway, sans-serif',
    Tangerine: 'Tangerine, cursive, sans-serif',
    Manrope: "'Manrope', sans-serif",
  },
  colors: {
    text: '#909296',
    lightText: '#fffbf0',
    brand: '#6a6e74',
    brandOppositeLight: '#dab182',
    brandOppositeDark: '#a8a8a8', //dc3300
    link: '#926f2c',
    linkDark: '#b07500',
    menu: '#966300',
    border: '#24252c',
    borderMenu: '#24252c',
    bg: '#0a0b0e',
    gold: '#404040',
    cite: '#6a6e74',
    button: '#a26c00',
    list: '#6a6e74',
    logo: '#966300',
    input: '#909296',
    close: 'white',
    ctaColor: '#a26c00',
    ctaHover: 'white',
    soldColor: '#909296',
    ctaBg: '#a8a8a8',
  },
  fontWeights: {
    medium: 300,
    normal: 500,
    bold: 700
  },
  radius: {
    small: 3,
    medium: 5,
    big: 10,
  },
  breakpoints,
  media,
}
