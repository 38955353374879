import * as React from "react";
import { Flex, Box } from "reflexbox";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import thumbnailSrc from "../../static/thumbnail_dead.jpg";
import Gallery from "../components/gallery";
import { Proposal } from "../components/proposal/Proposal";
import Modal, { ModalData } from "../components/modal/Modal";
import { GalleryContainer } from "../components/layout/container";
import { Ornament } from "../components/layout/ornament";
import { Center, H1 } from "../components/typography/styled/typography";
import {useState} from "react";
import { Lang, Translate } from "../components/tr";
import theme from '../components/styled/darkThemeVariables';

const Portfolio = ({ data, pageContext: { lang = Lang.cs }, location }) => {
  const [modalData, setModalData] = useState<ModalData | null>(null);
 /* const imgSets: ImgSet[] = useMemo(
    () =>
      data.allMarkdownRemark.edges.map(({ node }) =>
        getImageData(data.gallery, node)
      ),
    []
  );*/

  return (
      <Layout scrollDisable={modalData !== null} lang={lang} location={location} customTheme={theme}>
        <SEO lang={lang} title="Dark Oil Galllery" image={thumbnailSrc} />
        <Modal
          lang={lang}
          title={modalData !== null ? modalData.title : ""}
          data={modalData}
          onClose={() => setModalData(null)}
          isDark
        />
        {/*<Container>
          <Ornament />
          <Center>
            <H1>
              <Translate text={"Featured artwork for sale"} />
            </H1>
            <cite>
              “
              <Translate
                text={"The only good is knowledge and the only evil is ignorance"}
              />
              ” &mdash;Sókratés
            </cite>
          </Center>
          {imgSets.map((set) => {
            return (
              <FeaturedInSection
                key={set.image.alt}
                imgSet={set}
                onOpen={() => setModalData(set)}
                lang={lang}
              />
            );
          })}
        </Container>*/}

        <GalleryContainer>
          <Ornament />
          <Center>
            <H1>
              <Translate text={"Sales Gallery"} />
            </H1>
            <Flex justifyContent={"center"}>
              <Box width={[1, 2 / 3, 2 / 3]} mb={[3]}>
                <cite>
                  “
                  <Translate
                    text={"The only good is knowledge and the only evil is ignorance"}
                  />
                  ” &mdash;Sókratés
                </cite>
              </Box>
            </Flex>
          </Center>
          <Flex flexWrap={"wrap"}>
            <Box width={[1, 1, 1, 1]} py={3} mt={[3, 0, 0, 0]}>
              <Gallery
                nodes={data.allFile.nodes}
                meta={data.galleryMeta.deadArt}
                onOpenDetail={setModalData}
              />
            </Box>
          </Flex>
        </GalleryContainer>
        <Proposal />
      </Layout>
  );
};
export default Portfolio;

export const galleryQuery = graphql`
  query galleryDeadArtQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { glob: "**/dead-art/*.md" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          excerpt(pruneLength: 410)
          frontmatter {
            date
            year
            title
            tags
            directory
            dimension
            material
            variants {
              name
              price
            }
          }
        }
      }
    }

    gallery: allFile(
      filter: { relativePath: { glob: "gallery/dead-art/*/*.jpg" } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        base
        relativePath
        childImageSharp {
          id
          original {
            width
            height
            src
          }
          fluid(maxWidth: 400, quality: 100, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
          big: fluid(maxWidth: 1024, quality: 95, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 623, height: 450, quality: 100, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }

    galleryMeta: galleryYaml {
      deadArt {
        name
        availability
        title
        year
        dimension
        material
        variants {
          name
          price
        }
      }
    }

    allFile(
      filter: { relativePath: { glob: "gallery/dead-art/*.jpg" } }
      sort: { fields: [name], order: DESC }
    ) {
      nodes {
        base
        childImageSharp {
          id
          original {
            width
            height
            src
          }
          fixed(width: 400, height: 400, quality: 100) {
            width
            height
            src
          }
        }
      }
    }
  }
`;
